@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Gruppo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono&display=swap');


body {
  margin: 0;
  font-family:  'Gruppo', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:  'Gruppo', cursive;
}

/* width and height of the scrollbar */
::-webkit-scrollbar {
  width: 2px;
}

/* background color of the scrollbar */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color of the scrollbar handle */
::-webkit-scrollbar-thumb {
  background-color: black;
}
