/* .contact-label{
    font-weight: 900;
    color: greenyellow;
    font-size: 24px;
}

.contact-submit-button{
    width: 10vw;
    height: 5vh;
    background-color: greenyellow;
    border-radius: 20px;
    color: grey;
    font-weight: 700;
    font-size: 16px;
    box-shadow: 0 2px 8px rgba(13, 228, 13, 0.5);
    transition: all 0.3s ease-in-out;
}

.contact-submit-button:hover{
    cursor: pointer;
    opacity: 1.2;
    transform: translateY(-5px);
}

.contact-submit-button:active{
    background-color: rgba(255,0,0,1);
    color: white;
}

@media screen and (max-width:768px){
    .contact-submit-button{
        font-size: 12px;
        width: 100px;
    }
} */

/* .form-actual {
    display: flex;
    flex-direction: column;
    align-items: left; 
    justify-content: center; 
    transform: translateY(-15%);
    width: 100%;
    height: 100%;
    margin-top: 2rem;
  }

  .form-actual label{
    display: flex;
    flex-direction: row;
    align-items: flex-start; 
    justify-content: left; 
  }
  
  .form-actual input,
  .form-actual textarea {
    margin-top: 1rem; 
    margin-bottom: 1rem;
    width: 70%;
    height: fit-content;
    padding: 5px;
    background-color: rgba(255,255,255,0.3);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); 
    border: 3px solid gray;
    border-radius: 5px;
  }

  .form-actual textarea {
    resize: none;
    width: 70%;
    height: 30%;
  } */

/* .contact-back{
    display: flex;
    flex-direction: column;
    position: absolute;
    background-image: url(resources/contactBack.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100vw;
    height: 100vh;
    top:0;
    left:0;
    z-index: 0;
} */

/* .form-container{
    display: flex;
    position: absolute;
    top: 10vh;
    left: 0;
    width: 50vw;
    height: 90vh;
    background-color: transparent;
} */

.job-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: absolute;
    width: 40vw;
    height: 30vh;
    top: 50vh;
    left: 50vw;
    border-radius: 15px;
    border: 3px solid white;
    transform: translate(-50%,-50%);
    background: rgba(255, 255, 255, 0.3);
}

.job-logo{
    display: flex;
    position: relative;
    top: 50%;
    right: 0;
    width: 25%;
    height: 75%;
    transform: translateY(-50%);
    margin-right: 1rem;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    background-image: none;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 5;
}


.job-title{
    color: white;
    font-size: 2vw;
    font-weight: 900;
    height:20%;
    width: 75%;
    align-items: center;
    margin-left: 1rem;
    margin-top: 1rem;
}

.job-sub-title{
    display: flex;
    position: absolute;
    top:20%;
    left: 0;
    margin-top: 1rem;
    color: aqua;
    font-size: 1.25vw;
    font-weight: 500;
    height:20%;
    width: 75%;
    margin-left: 1rem;
}

.job-sub-sub-title{
    color: greenyellow;
    font-size: calc(2vw+2vh);
    font-weight: bolder;
    height:fit-content;
    display: flex;
    position: absolute;
    top:35%;
    left:0;
    width: 70%;
    margin-left: 1rem;
}

@media screen and (max-width: 1280px){
    .job-title{
        font-size: 2vw;
    }

    .job-sub-title{
        font-size: 1.5vw;
    }

    .job-sub-sub-title{
        font-size: 12px;
        width: 60%;
    }
}

@media screen and (max-width: 978px){
    .job-container{
        display: flex;
        width: 40vw;
        height: 65vw;
    }

    .job-title{
        font-size: 2.5vw;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
    }

    .job-sub-title{
        font-size: 1.5vw;
        display: flex;
        height: fit-content;
        width: 90%;
        position: absolute;
        top:15%;
        justify-content: center;
        align-items: center;
    }
    
    .job-sub-sub-title{
        font-size: 2vw;
        display: flex;
        width: 90%;
        position: absolute;
        top:25%;
        height: auto;
        justify-content: center;
        align-items: center;
    }

    .job-logo{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left:50%;
        top:85%;
        transform: translate(-50%,-50%);
        margin-top: 1rem;
    }
}

@media screen and (max-width: 676px){
    .job-container{
        display: flex;
        width: 90vw;
        height: 70vh;
        justify-content: space-between;
    }

    .job-title{
        font-size: 8vw;
        display: flex;
        width: 90%;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
    }

    .job-sub-title{
        font-size: 5vw;
        display: flex;
        height: fit-content;
        width: 90%;
        justify-content: center;
        align-items: center;
    }
    
    .job-sub-sub-title{
        font-size: 3vw;
        display: flex;
        width: 85%;
        height: auto;
        position: absolute;
        left:25%;
        top:35%;
        transform: translateX(-25%);
        justify-content: center;
        align-items: center;
    }

    .job-logo{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left:50%;
        top:80%;
        aspect-ratio: 3/4;
        transform: translate(-50%,-50%);
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

.publications-background{
    background-image:linear-gradient(rgba(0, 0, 0, 1), transparent, transparent, transparent, rgba(255, 255, 255, 0)), url(resources/PublicationsBack.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: flex-start;
    justify-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.education-background{
    background-image:linear-gradient(rgba(0, 0, 0, 1), transparent, transparent, transparent, rgba(255, 255, 255, 0)), url(resources/EducationBackground.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: flex-start;
    justify-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
}

.edu-icon{
    color: white;
    width: 2vw;
    height: 2vw;
    display: flex;
    position: absolute;
    top:35%;
    right:6%;
    z-index: 6;
}

body .publication-container{
    display: flex;
    flex-direction: column;
    width: 50vw;
    height: 15vh;
    position: relative;
    top: 5vh;
    left: 50%;
    border-radius: 10px;
    border: 2px solid white;
    transform: translateX(-50%);
    background-color: rgba(255,255,255,0.3);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); 
}

.education-container{
    display: flex;
    flex-direction: column;
    width: 40vw;
    height: 10vh;
    position: relative;
    top: 5vh;
    left: 50%;
    margin-bottom: 5vh;
    padding: 1rem;
    border-radius: 10px;
    transform: translateX(-50%);
    background-color: rgba(255,255,255,0.3);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); 
}

.education-container:hover {
    opacity: 1.1;
}


/* @keyframes jumpInfinite {
    0% {
      margin-top: 0;
    }
    50% {
      margin-top: 20px;
    }
    100% {
      margin-top: 0;
    }
  }

.down-arrow {
    position: absolute;
    top: calc(100vh - 60px);
    left: calc(50% - 14px);
    width: 0;
    height: 30px;
    border: 2px solid;
    border-radius: 2px;
    color: whitesmoke;
    animation: jumpInfinite 1.5s infinite;
}
  
.down-arrow:after {
    content: " ";
    position: absolute;
    top: 12px;
    left: -10px;
    width: 16px;
    height: 16px;
    border-bottom: 4px solid;
    border-right: 4px solid;
    border-radius: 4px;
    transform: rotateZ(45deg);
} */

.publication-title{
    color: white;
    display: flex;
    position: absolute;
    font-size: 1vw;
    font-weight: 700;
    height:fit-content;
    width: 85%;
    align-items: center;
    margin-left: 1rem;
    margin-top: 1rem;
}

.education-title{
    color: white;
    display: flex;
    position: absolute;
    font-size: 1vw;
    font-weight: 700;
    height:fit-content;
    width: 85%;
    top:0;
    align-items: center;
    margin-left: 1rem;
    margin-top: 1rem;
}

.publication-sub-title{
    color: greenyellow;
    display: flex;
    position: absolute;
    top:30%;
    font-size: 1vw;
    font-weight: 500;
    height:20%;
    align-items: center;
    margin-left: 1rem;
    margin-top: 0.5rem;
}

.publication-sub-sub-title{
    color: greenyellow;
    display: flex;
    position: absolute;
    font-size: 1vw;
    font-weight: bolder;
    height:20%;
    top:55%;
    align-items: center;
    margin-left: 1rem;
    margin-top: 0.5rem;
}

@media screen and (min-width: 875px) and (max-width: 1280px){

    body .publication-container{
        display: flex;
        flex-direction: column;
        width: 50vw;
        height: 15vh;
        position: relative;
    }

    .education-title{
        color: white;
        font-size: 0.75vw;
        font-weight: 700;
        height:fit-content;

    }
    
    .publication-sub-title{
        color: greenyellow;
        font-size: 1vw;
        font-weight: 500;
        height:fit-content;
    }
    
    .publication-sub-sub-title{
        color: greenyellow;
        font-size: 1vw;
        font-weight: bolder;
        height:fit-content;
    }

    .publication-icons{
        display: flex;
        position: absolute;
        justify-content: flex-start;
        flex-direction: column;
        right: 0;
        width: fit-content;
        margin-top: auto;
    }
    
    .publication-icons:hover{
        cursor: pointer;
    }
    
    .p-icon-itself{
        width: 10px;
        height: 10px;
    }
}

@media screen and (min-width: 636px) and (max-width: 1000px){

    body .publication-container{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        width: 40vw;
        height: 25vh;
        position: relative;
    }

    .publication-title{
        color: white;
        font-size: 1.5vw;
        font-weight: 700;
        height:fit-content;
        width: 95%;

    }
    
    .publication-sub-title{
        color: greenyellow;
        font-size: 1.5vw;
        font-weight: 500;
        height:fit-content;
        position: absolute;
        top:35%;
        width: 95%;
    }
    
    .publication-sub-sub-title{
        color: greenyellow;
        font-size: 1.5vw;
        font-weight: bolder;
        height:fit-content;
        position: absolute;
        top:60%;
    }

    body .publication-icons{
        position: absolute;
        top:85%;
        left: 0;
        width: fit-content;
        margin-top: auto;
    }
    
    .publication-icons:hover{
        cursor: pointer;
    }
    
    body .p-icon-itself{
        width: 20px;
        height: 20px;
    }
}

@media screen and (min-width: 0px) and (max-width: 635px){

    body .publication-container{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        width: 50vw;
        height: 60vh;
        position: relative;
    }

    .publication-title{
        color: white;
        font-size: 4vw;
        font-weight: 700;
        height:fit-content;
        width: 90%;
        margin-bottom: 1rem;
    }
    
    .publication-sub-title{
        color: greenyellow;
        font-size: 4vw;
        font-weight: 500;
        height:fit-content;
        position: absolute;
        top:35%;
        width: 90%;
        margin-top:1rem;
    }
    
    .publication-sub-sub-title{
        color: greenyellow;
        font-size: 4vw;
        font-weight: bolder;
        height:fit-content;
        position: absolute;
        top:65%;
    }

    body .publication-icons{
        position: absolute;
        top:85%;
        left: 0;
        width: fit-content;
        margin-top: auto;
    }
    
    .publication-icons:hover{
        cursor: pointer;
    }
    
    body .p-icon-itself{
        width: 20px;
        height: 20px;
    }
}

.education-sub-title{
    color: white;
    font-size: calc(2vw+2vh);
    font-weight: 500;
    height:20%;
    position: absolute;
    top:40%;
    align-items: center;
    margin-left: 1rem;
    margin-top: 0.5rem;
}

.education-sub-sub-title{
    color: white;
    font-size: calc(2vw+2vh);
    font-weight: bolder;
    position: absolute;
    top:60%;
    height:20%;
    align-items: center;
    margin-left: 1rem;
    margin-top: 0.5rem;
}

@media screen and (max-width: 1280px){
    .edu-icon{
        visibility: hidden;
    }

    .education-title {
        font-size: 14px;
        margin-bottom: 0.5vh;
    }
}

@media screen and (max-width: 300px){
    .edu-icon{
        visibility: hidden;
    }

    .education-title {
        width: 100%;
        font-size: 10px;
        margin-bottom: 0.25vh;
    }
}

@media screen and (max-width: 768px) {

    .education-container{
        display: flex;
        width: 60vw;
        height:15vh;
        justify-content: center;
        align-items: center;
    }
    .education-title {
        font-size: 16px;
        margin-bottom: 0.5vh;
    }

    .education-sub-title,
    .education-sub-sub-title {
        font-size: 14px;
        margin-bottom: 0.5vh;
    }

    .edu-icon {
        visibility: hidden;
    }
}

@media screen and (max-width: 644px) {

    .education-background{
        display: flex;
        justify-content: space-between;
        justify-content: center;
        align-items: center;
        gap: 2vh;
        height: 100%;
    }

    .education-container {
      width: 85vw;
      height: 20vh;
      position: relative;
      top: 0;
      left: 0;
      transform: none;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 3vw;
    }
  
    .edu-icon {
      visibility: hidden;
    }
}

.publication-icons{
    color: white;
    height:20%;
    display: flex;
    position: absolute;
    right:5%;
    top:50%;
    transform: translateY(-50%);
    flex-direction: row;
    align-items: center;
    margin-left: 1rem;
    margin-top: 0.5rem;
}

.publication-icons:hover{
    cursor: pointer;
}

.p-icon-itself{
    width: 30px;
    height: 30px;
}

/* .filter-container{
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: flex-start;
    top:0;
    align-items: center;
    width: 100vw;
    height: 10vh;
    color: white;
} */


/* .filter-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    width: 100vw;
    height: 10vh;
    align-items: center;
    background-color:transparent;
    z-index:4;
    gap: 1rem;
}

.filter-button{
    display: flex;
    position: relative;
    color: white;
    background-color: red;
    width: calc(20% * 75vw);
    height: fit-content;
    z-index: 5;
    margin-left: 1vw;
} */

/* .dropdown {
    display: flex;
    position: relative;
    color: greenyellow;
    cursor: default;
    width: 25vw;
    margin-left: 2vw;
    height: auto;
    z-index: 5;
    border-radius: 20px;
    font-family:  'Gruppo', cursive;
}
  
.dropdown .selected-value {
    position: relative;
}
  
.dropdown .selected-value .arrow {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    font-family: 'Gruppo', cursive;
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: " ";
    position: absolute;
    margin-right: 5%;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: fit-content;
    height: fit-content;
}

.dropdown .selected-value .arrow.open {
    border-radius: 20px;
    font-family: 'Gruppo', cursive;
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
    color: greenyellow;
}
  
.dropdown .selected-value input {
    border-radius: 20px;
    font-family:  'Gruppo', cursive;
    line-height: 1.5;
    font-size: 1rem;
    background-color: black;
    border: 1px solid greenyellow;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: default;
    outline: none;
    padding: 8px 52px 8px 10px;
    transition: all 200ms ease;
    width: 100%;
    color: greenyellow;
}

.dropdown .options {
    border-radius: 20px;
    font-family:  'Gruppo', cursive;
    color: greenyellow;
    display: none;
    background-color: black;
    border: 1px solid greenyellow;
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
}

.dropdown .options.open {
    font-family:  'Gruppo', cursive;
    display: block;
    color: greenyellow;
}

.dropdown .option {
    font-family:  'Gruppo', cursive;
    box-sizing: border-box;
    color: greenyellow;
    cursor: pointer;
    display: block;
    padding: 8px 10px;
}

.dropdown .option.selected,
.dropdown .option:hover {
    font-family:  'Gruppo', cursive;
    background-color: lime;
    color: black;
} */


.filter-field-output{
    display: flex;
    position: absolute;
    top:10vh;
    z-index: 4;
    width: 100%;
    height: 5vh;
    background-color: transparent;
    align-items: center;
    color: transparent;
    font-family: 'Ubuntu Mono', monospace;
    margin-top: 1vh;
}

.tag-button {
  background-color: #c2fbd7;
  border-radius: 100px;
  box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;
  color: green;
  display: inline-block;
  font-family: CerebriSans-Regular,-apple-system,system-ui,Roboto,sans-serif;
  padding: 0.5vh 1vw;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 0.75vw;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.tag-button:hover {
  box-shadow: rgba(44,187,99,.35) 0 -25px 18px -14px inset,rgba(44,187,99,.25) 0 1px 2px,rgba(44,187,99,.25) 0 2px 4px,rgba(44,187,99,.25) 0 4px 8px,rgba(44,187,99,.25) 0 8px 16px,rgba(44,187,99,.25) 0 16px 32px;
  transform: scale(1.05) rotate(-1deg);
}

/* .tag-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    
}

.tag-container span {
    margin-left: 1vw;
    white-space: nowrap;
    font-size: 1vw;
}

.tag-container .tag-list {
    margin-top: 1vh;
    margin-left: 1vw;
    margin-right: 1vw;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
    width: 95%;
}

.tag-container .tag-item {
    border-radius: 5px;
    background-color: #f1f1f1;
    padding: 5px 10px;
    margin: 5px;
} */



/* .glass-container-project {
    display: flex;
    /* flex-wrap: wrap; */
    /* flex-direction: row;
    height: 90vh;
    width: 100vw;
    position: absolute;
    top: 20vh;
    background-color: transparent;
    justify-content: center;
} */

@media screen and (max-width: 1280px){
/* 
    body .glass-container-project {
        display: flex;
        flex-direction: column;
        height: 100vh;
        width: 100vw;
        position: relative;
        background-color: transparent;
        justify-content: center;
        align-items: center;
        overflow-y: scroll;
    } */

    body .glass-container-project > .glass-card-project {
        margin: 2.5% 0;
    }

    body .glass-card-project {
        width: 75vw;
        height: 50%;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); 
        color: #fff;
        background-color: rgba(255,255,255,0.1);
        border: 1px solid rgba(255,255,255,0.1);
        backdrop-filter: blur(10px);
        animation: cardFlipAlt 0.8s ease-in-out forwards;
        overflow-y: auto;
    }

    body .glass-project-image{
        display: none;
    }

    body .tag-container span {
        margin-left: 12px;
        white-space: nowrap;
        font-size: 12px;
    }

    body .glass-card-project-title{
        width: 100%;
        height: 5%;
        margin-top: 5vh;
        margin-bottom: 1vh;
        text-align: center;
        color: white;
        font-size: 16px;
    }

    body .tag-button {
        background-color: #c2fbd7;
        border-radius: 100px;
        box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;
        color: green;
        display: inline-block;
        font-family: CerebriSans-Regular,-apple-system,system-ui,Roboto,sans-serif;
        padding: 0.5vh 1vw;
        text-align: center;
        text-decoration: none;
        transition: all 250ms;
        border: 0;
        min-width: 50px;
        font-size: 12px;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
    }

    body .glass-container-project-alt{
        rotate: 90deg;
    }

    body .glass-card-project-alt{
        height: 60%;
        width: 60%;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0); 
        rotate: 90deg;
    }
}


@keyframes cardFlip {
    from {
      transform: perspective(600px) rotateY(0deg);
    }
    to {
      transform: perspective(600px) rotateY(-180deg);
    }
}

@keyframes cardFlipAlt {
    from {
      transform: perspective(600px) rotateY(-180deg);
    }
    to {
      transform: perspective(600px) rotateY(0);
    }
}
  
/* .glass-card-project {
    display: flex;
    flex-direction: column;
    width: 20vw;
    height: 60vh;
    margin: 2.5%;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); 
    color: #fff;
    background-color: rgba(255,255,255,0.1);
    border: 1px solid rgba(255,255,255,0.1);
    backdrop-filter: blur(10px);
    animation: cardFlipAlt 0.8s ease-in-out forwards;
    overflow-y: auto;
}

.glass-card-project-container {
    display: flex;
    flex-direction: column;
    width: 30vw;
    height: 75vh;
    margin: 2.5%;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); 
    background-color: transparent;
    backdrop-filter: blur(10px);
} */


/* .glass-card-project-alt {
    display: flex;
    flex-direction: column;
    width: 20vw;
    height: 60vh;
    margin: 2.5%;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0); 
    color: #fff;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent;
    backdrop-filter: blur(10px);
    animation: cardFlip 0.8s ease-in-out forwards;
    overflow-y: auto;
} */



/* .glass-card-project-title{
    width: 100%;
    height: 5%;
    margin-top: 5vh;
    margin-bottom: 1vh;
    text-align: center;
    color: white;
    font-size: 22px;
} */

/* .glass-project-image{
    background-color: transparent;
    width: 25%;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 1vh;
} */

.glass-project-image-src{
    border-radius: 50%;
    aspect-ratio: 1/1;
    scale: 35%;
}

/* .footer{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    font-size: 14px;
    top: 95vh;
    left: 0.5vw;
    width: fit-content;
    height: fit-content;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5%;
    z-index: 3;
}

@media screen and (max-width:500px) {
    .footer{
        font-size: 10px;
    }
} */

.home-back{
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: black;
}

/* .home-top{
    width: 100vw;
    height: 5vh;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background: gainsboro;
    z-index: 1;
    box-shadow: 
    -2px -2px 0 #fff, 
    4px 4px 0 #999;
} */

/* .home-icon{
    height: calc(0.75*5vh);
    width: calc(0.75*5vh);
    font-size: large;
    font-weight: 900;
    color: red;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: auto;
    margin-bottom: auto;
    margin-top: auto;
    margin-right: 1vw;
    border-radius: 50%;
    z-index: 2;
}

.home-icon:hover{
    cursor: pointer;
} */


/* .home-icon-2{
    height: calc(0.75*5vh);
    width: calc(0.75*5vh);
    font-size: large;
    font-weight: 900;
    color: black;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-right: auto;
    margin-bottom: auto;
    margin-top: auto;
    margin-left: 1vw;
    z-index: 2;
} */

/* .home-icon-3{
    height: calc(0.75*5vh);
    width: calc(0.75*5vh);
    font-size: large;
    font-weight: 900;
    color: aqua;
    position: absolute;
    top: 95vh;
    right: 1vw;
    margin-bottom: 5px;
    border-radius: 50%;
    z-index: 2;
}

.home-icon-3:hover{
    cursor: pointer;
    filter: brightness(150%);
} */


.no-page-title{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: whitesmoke;
    font-size: calc(85vw+8vh);
    font-weight: 900;
}


/* .main-scene-main{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100vw;
    height: 50vh;
} */
/* 
.input-field-main{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    width: 100vw;
    height: fit-content;
    z-index: 3;
} */

/* .input-field-label{
    font-family: 'Ubuntu Mono', monospace;
    color: greenyellow;
    margin-top: 1vh;
    flex-shrink: 0;
} */

/* .input-field-input{
    margin-top: 1vh;
    background-color: black;
    color: whitesmoke;
    font-family: 'Ubuntu Mono', monospace;
    margin-left: 1vw;
    flex-grow: 1;
} */

/* .input-field-output{
    width: 100%;
    height: 2vh;
    background-color: transparent;
    color: whitesmoke;
    font-family: 'Ubuntu Mono', monospace;
    margin-top: 1vh;
  } */